import React from "react";
import { Profile } from "src/_services";
import Select from "react-select";
export class ProfileSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      profiles: [],
      selected: []
    };
    this.handleSelectInputChange = this.handleSelectInputChange.bind(this);
  }
  componentDidMount() {
    this.getData();
  }
  buildValue() {
    const { value } = this.props;
    const { profiles } = this.state;
    if (value) {
      this.setState({
        selected: profiles.filter(e => value && value.includes(e.id))
      });
    }
  }
  getData() {
    new Profile({
      network_id: this.props.networkId,
      type: "children",
      page_size: 9999
    })
      .all()
      .then(res => {
        let profiles = res.results.map(e => {
          return {
            id: e.id,
            value: e.id,
            label: `${e.full_name} ${e.level === 0 ? " (You)" : ""} - Cấp ${
              e.level
            }`
          };
        });
        this.setState({ profiles });
        setTimeout(() => {
          this.buildValue();
        }, 300);
      });
  }
  handleSelectInputChange(value) {
    this.setState({
      selected: value
    });
    this.emitValue();
  }
  emitValue() {
    setTimeout(() => {
      const { selected } = this.state;
      if (this.props.onChange)
        this.props.onChange({
          target: {
            name: this.props.name || "profiles",
            value: selected.map(e => e.id)
          }
        });
    }, 300);
  }
  viewOnly() {
    return "";
  }
  render() {
    const { profiles, selected } = this.state;
    if (this.props.readonly) return <>{this.viewOnly()}</>;
    return (
      <div className="profile-select ml-1 mr-1">
        <Select
          className="zindex1 select-inline-form"
          closeMenuOnSelect={true}
          placeholder={"Chọn đại lý"}
          cacheOptions
          isMulti
          options={profiles}
          onChange={this.handleSelectInputChange}
          name="profiles"
          value={selected}
        />
      </div>
    );
  }
}
