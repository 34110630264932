import { combineReducers } from 'redux';
import { authentication } from './authentication.reducer';
import { registration } from './registration.reducer';
// import { users } from './users.reducer';
import { alert } from './alert.reducer';
import { submenu } from './submenu.reducer';
import { sidebarShow } from './sidebar.show';
const rootReducer = combineReducers({
  authentication,
  registration,
  // users,
  alert,
  submenu,
  sidebarShow
});

export default rootReducer;