import React, { Component } from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Loading } from "src/_components";

export class VCarousel extends Component {
  render() {
    const { items } = this.props
    if (!items) return <Loading />
    return (
      <Carousel className="v-carousel" dynamicHeight={true} showThumbs={items.length > 1} showIndicators={items.length > 1}>
        {items.map(item => {
          return (<div key={item.src}>
            <img src={item.src} key={item.src} alt="" />
          </div>)
        })}
      </Carousel>
    );
  }
}
