import Cookies from 'js-cookie'
export * from './fake-backend';
export * from './history';
export * from './store';
export * from './auth-header';
export * from './common';
export * from './storage';
export const getStyle = level => ({ paddingLeft: `${level * 20}px` });

export const getIcon = isExpanded =>
  `fa fa-fw fa-${isExpanded ? 'caret-down' : 'caret-right'}`;

export const event = callback => e => {
  e.preventDefault();
  e.stopPropagation();
  callback && callback();
};
export const checkTokenInput = () => {
  var cookieToken = Cookies.get('imontokie')
  var inputToken = document.querySelectorAll('input[name=token]')
  if (inputToken && inputToken.length) {
    if (inputToken[0].value !== '{{ message }}')
      localStorage.setItem('Token', inputToken[0].value)
  } else if (cookieToken){
    localStorage.setItem('Token', cookieToken)
  }
};
