import React from "react";
import { Loading, Avata, VIcon } from "src/_components";
import clsx from "clsx";
import { cleanSearchKey } from "src/_helpers";
import { ItemService } from "src/_services";
import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CLink,
  CInput,
  CFormGroup,
  CCol,
  CPagination
} from "@coreui/react";
import { isMobile } from "react-device-detect";
export class ItemsSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      datas: null,
      isOpen: false,
      loading: true,
      pageSize: Number(process.env.REACT_APP_PAGE_SIZE),
      pages: 1,
      query: {
        page: 1,
        page_size: 9999
      },
      hasDataFromServer: false
    };
    this.setModal = this.setModal.bind(this);
    this.selectedItem = this.selectedItem.bind(this);
    this.selectedItems = this.selectedItems.bind(this);
    this.filterChange = this.filterChange.bind(this);
    this.pageChange = this.pageChange.bind(this);
  }
  pageChange(newPage, oldpage) {
    if (this.state.query.page !== newPage && !oldpage) {
      this.updateStateQuery("page", newPage);
    }
  }
  updateStateQuery(name, value) {
    var query = { ...this.state.query };
    query[name] = value;
    this.setState({ query });
  }
  filterChange(e) {
    let filterText = e.target.value;
    this.setState({ filterText });
    this.updateStateQuery("page", 1);
  }
  selectedItems() {
    this.setModal();
    if (this.props.onChange) {
      const { datas } = this.state;
      this.props.onChange({
        target: {
          name: this.props.name,
          value: (datas || []).filter(e => e.selected)
        }
      });
    }
  }
  selectedItem(item) {
    let datas = this.state.datas;
    this.setState({
      datas: datas.map(e => {
        if (e.id === item.id) e.selected = !item.selected;
        return e;
      })
    });
  }
  setModal(status) {
    this.setState({ isOpen: status });
    const { hasDataFromServer } = this.state;
    if (status && !hasDataFromServer) this.getItems();
    if (status && hasDataFromServer) {
      this.resetSelected();
    }
  }
  resetSelected() {
    const { datas } = this.state;
    if (!datas) return false;
    this.setState({
      datas: datas.map(e => {
        e.selected = false;
        return e
      })
    });
  }
  getItems() {
    if (this.state.datas) {
      let datas = this.state.datas;
      this.setState({
        datas: datas.map(e => {
          e.selected = false;
          return e;
        })
      });
      return false;
    }
    let query = this.props.query;
    new ItemService({ ...query, page: 1, page_size: 999 })
      .all()
      .then(response => {
        let datas = response.results.map(e => {
          e.selected = false;
          e.cleanSearchKey = cleanSearchKey(e.name);
          return e;
        });
        var bonus = 1;
        if (response.count % Number(process.env.REACT_APP_PAGE_SIZE) === 0) {
          bonus = 0;
        }
        this.setState({
          pages:
            parseInt(response.count / Number(process.env.REACT_APP_PAGE_SIZE)) +
            bonus
        });
        this.setState({ hasDataFromServer: true });
        this.setState({ datas });
      });
  }
  button() {
    return (
      <div>
        <CButton
          color="success"
          className="btn-block"
          onClick={() => this.setModal(true)}
        >
          <VIcon name="cilPlus" />
          {this.props.placeholder || "Chọn sản phẩm"}
        </CButton>
      </div>
    );
  }
  filterForm() {
    return (
      <CFormGroup row>
        <CCol md="12">
          <CInput
            type="text"
            name="filter"
            placeholder="Nhập để tìm kiếm"
            onChange={this.filterChange}
          />
        </CCol>
      </CFormGroup>
    );
  }
  getItemsData() {
    const { pageSize, query } = this.state;
    var datas = this.getDataByFilter();
    var paginged = datas.slice(
      (query.page - 1) * pageSize,
      query.page * pageSize
    );
    return paginged;
  }
  getDataByFilter() {
    const { datas, filterText } = this.state;
    var filtrData = (datas || []).filter(item => {
      return (
        !filterText || item.cleanSearchKey.includes(cleanSearchKey(filterText))
      );
    });
    return filtrData;
  }
  render() {
    const { query, isOpen, datas } = this.state;
    const selected = (datas || []).filter(e => e.selected).length;
    const dataa = this.getItemsData();
    var dataByFilter = this.getDataByFilter();
    var bonus = 1;
    if (dataByFilter.length % Number(process.env.REACT_APP_PAGE_SIZE) === 0) {
      bonus = 0;
    }
    const pages =
      parseInt(dataByFilter.length / Number(process.env.REACT_APP_PAGE_SIZE)) +
      bonus;
    return (
      <>
        {this.button()}
        <CModal
          show={isOpen}
          onClose={() => this.setModal()}
          size="xl"
          color="primary"
        >
          <CModalHeader closeButton className="p-2">
            <CModalTitle>Chọn sản phẩm</CModalTitle>
          </CModalHeader>
          <CModalBody>
            {!dataa ? (
              <Loading />
            ) : (
              <>
                {!isMobile && this.filterForm()}
                <div className="product-list mb-2">
                  {dataa.length > 0
                    ? dataa.map((item, index) => {
                        return (
                          <div
                            key={index}
                            onClick={() => this.selectedItem(item)}
                            className={clsx({
                              // hidden: !(
                              //   !filterText || item.name.includes(filterText)
                              // ),
                              "product-item": true,
                              "mobile-view": isMobile
                            })}
                          >
                            <div
                              className={clsx({
                                "product-wrap": true,
                                selected: item.selected
                              })}
                            >
                              <div className="product-image">
                                <CLink to={item.link}>
                                  <Avata src={item.main_picture} alt="" />
                                </CLink>
                              </div>
                              <div className="product-info">
                                <div className="product-name">
                                  <CLink to={item.link}>{item.name}</CLink>
                                </div>
                                <div className="product-cat">
                                  {item.base_price}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    : "Không tìm thấy sản phẩm"}
                </div>
              </>
            )}
          </CModalBody>
          <CModalFooter className="p-2">
            <div className="wp100">
              <div>
                {isMobile && this.filterForm()}
                {pages > 1 && (
                  <CPagination
                    activePage={query.page}
                    onActivePageChange={this.pageChange.bind(this)}
                    pages={pages}
                    doubleArrows={true}
                    align="start"
                  />
                )}
              </div>
              <div className="text-right">
                {selected > 0 && (
                  <>
                    <CButton
                      size="sm"
                      color="primary"
                      onClick={() => this.selectedItems()}
                    >
                      Chọn {selected} sản phẩm
                    </CButton>{" "}
                  </>
                )}
                <CButton
                  size="sm"
                  color="secondary"
                  onClick={() => this.setModal()}
                >
                  Hủy
                </CButton>
              </div>
            </div>
          </CModalFooter>
        </CModal>
      </>
    );
  }
}
