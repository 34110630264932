import {
  CBadge,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle
} from '@coreui/react';
import { CopyToClipboard } from 'src/_components'
import React, { Component } from 'react';
import QRCode from "react-qr-code";
export class NetworkInviteLink extends Component {
  constructor(props) {
    super(props);
    this.openPopup = this.openPopup.bind(this);
    this.state = {
      hasPopup: false,
      code: ''
    };
    this.link = `${window.location.origin}/#/invite/${this.props.network.code}`
  }
  openPopup() {
  }
  render() {
    const { network } = this.props
    const { hasPopup } = this.state
    if (!network) return
    return (
      <>
        <CBadge onClick={() => this.setState({ hasPopup: true })} className="pointer" color="success">Mã: <>{network.code}</></CBadge>
        {hasPopup && <CModal
          show={hasPopup}
          onClose={() => this.setState({ hasPopup: false })}
          color="primary"
        >
          <CModalHeader closeButton>
            <CModalTitle>{network.full_name}</CModalTitle>
          </CModalHeader>
          <CModalBody>
            <p>Mã giới thiệu: <CopyToClipboard>{network.code}</CopyToClipboard></p>
            <p>Link giới thiệu: <CopyToClipboard>{this.link}</CopyToClipboard></p>
            <div className="text-center p-3">
              <p>Hoặc quét mã:</p>
              <QRCode value={this.link} />
            </div>
          </CModalBody>
        </CModal>
        }
      </>
    )
  }
}