import React, { Component } from 'react';
import { Loading, Avata } from "src/_components";
import Slider from "react-slick";
import { ItemService } from "src/_services";
import { CLink } from "@coreui/react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const carouselSettings = {
  // dots: true,
  className: "center",
  infinite: true,
  centerPadding: "60px",
  slidesToShow: 3,
  swipeToSlide: true,
  autoplay: true,
  autoplaySpeed: 3000
};
export class ProductCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Items: false,
    }
  }
  componentDidMount() {
    this.getData();
  }
  getData() {
    let q = { ...this.props.query };
    new ItemService(q)
      .all()
      .then(response => {
        this.setState({
          Items: response.results.map(e => {
            e.link = `/network/${this.props.query.network_id}/network-items/${e.id}/view`
            return e;
          })
        });
      })
      .catch(() => {
        this.setState({ Items: [] });
      });
  }
  render() {
    const { Items } = this.state
    if (!Items) return <Loading />
    return (<>
      {Items.length > 0 && <>
        <h4 className="bt-title mt-2 mb-2">Sản phẩm</h4>
        <Slider {...carouselSettings}>
          {Items.map(item => {
            return (<div className="product-item" key={item.id}>
              <div className="product-wrap">
                <div className="product-image">
                  <CLink to={item.link}>
                    <Avata src={item.main_picture} alt="" />
                  </CLink>
                </div>
                <div className="product-info">
                  <div className="product-name"><CLink to={item.link}>{item.name}</CLink></div>
                </div>
              </div>
            </div>)
          })}
        </Slider>
      </>}
    </>)
  }
}