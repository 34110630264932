import React, { Component } from "react";
import { ArticleService } from "src/_services";
import { Loading, Avata } from "src/_components";
import {
  CLink,
} from "@coreui/react";
export class PostList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Items: false,
    }
  }
  componentDidMount() {
    this.getData();
  }
  
  getData () {
    let q = { ...this.props.query, page_size: this.props.count + 1 };
    const itemId = this.props.type === 'item' ? `${this.props.query.item_id}/` : ''
    new ArticleService(q, this.props.type).noLoading().noError()
      .all()
      .then(response => {
        this.setState({
          Items: response.results.map(e => {
            e.link = `/network/${this.props.NetworkData.id}/${this.props.type}-articles/${itemId}${e.id}/view`;
            return e;
          }).filter(e => e.type > 1)
        });
      })
      .catch(() => {
        this.setState({ Items: [] });
      });
  }
  render () {
    const {Items} = this.state
    if (!Items) return <Loading/>
    return (<>
      {Items.length > 0 && <>
      <h4 className="bt-title mt-2 mb-2">Bài viết</h4>
      {Items.map(item => {
        return (<div className="article-item d-flex" key={item.id}>
          <div className="article-thumb">
            <CLink to={item.link}>
              <Avata src={item.ref_link_picture} alt="" />
            </CLink>
          </div>
          <CLink to={item.link}>{item.title}</CLink>
        </div>)
      })}
      </>}
    </>)
  }
}