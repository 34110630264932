import BaseService from './base.service'
import { storage } from 'src/_helpers'

export class AddressService extends BaseService {
  /**
   * The constructor for the ArtistService.
   *
   * @param {Object} parameters The query parameters.
   */
  constructor(parameters = {}) {
    super('static/hcvietnam', parameters)
  }
  provinces () {
    return new Promise((resolve) => {
      var fromStorage = storage.get('provinces')
      if (fromStorage) {
        return resolve(fromStorage)
      } else {
        return this.sendRequestWithBaseUrl('get', `${this.endpoint}/tinh_tp.json`, null, '').then(response => {
          storage.set('provinces', (response))
          resolve(response)
        })
      }
    })
    
  }
  districts (provinceId) {
    return this.sendRequestWithBaseUrl('get', `${this.endpoint}/quan-huyen/${provinceId}.json`, null, '')
  }
}
