import React, { Component } from 'react';
import { connect } from 'react-redux';
import { HashRouter, Route, Switch } from 'react-router-dom';
// import { withTranslation } from 'react-i18next';
import { alertActions } from './_actions';
import { history } from './_helpers';
import {
  Loading
} from "src/_components";
import {
  CToast,
  CToastBody,
  CToastHeader,
  CToaster
} from '@coreui/react'
import './scss/style.scss';

import 'src/plugins/axios'
import i18n from 'src/i18n'
const loading = (
  <Loading/>
)

// Containers
const TheLayoutWarranty = React.lazy(() => import('./containers/TheLayoutWarranty'));


const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));

class App extends Component {
  constructor(props) {
    super(props);
    if (window.gtag) {
      window.gtag("event", "page_view", {
        page_path: window.location.pathname + window.location.hash,
        page_title: 'Lazychef'
      })
      history.listen(() => {
        var title = 'Lazychef'
        if (window.location.hash.includes('Article/4dac1406-7f02-4bd9-8353-27942ec0e270')) {
          title = 'Hướng dẫn sử dụng'
        }
        if (window.location.hash.includes('active')) {
          title = 'Kích hoạt bảo hành'
        }
        if (window.location.hash.includes('info')) {
          title = 'Tra cứu thông tin bảo hành'
        }
        if (window.location.hash.includes('Article/30dbd1c4-137b-4584-9e84-fc23c1e0d23d')) {
          title = 'Chính sách bảo hành'
        }
        if (window.location.hash.includes('Article/f4aaae96-dd42-4938-9932-2416c72d9862')) {
          title = 'Thông tin liên hệ'
        }
        if (window.location.hash.includes('Article/939fb632-0c11-4229-b545-53518a523705')) {
          title = 'Địa điểm bảo hành'
        }
        if (window.location.hash.includes('Feedback')) {
          title = 'Feedback'
        }
        window.gtag("event", "page_view", {
          page_path: window.location.pathname + window.location.hash,
          page_title: title
        })
      })
    }
  }
  render() {
    const { alert } = this.props;
    return (
      <HashRouter>
          <CToaster position={'top-right'}>
            {
              alert.map((toast, key)=>{
                return(
                  <CToast
                    key={'toast' + key}
                    show={true}
                    autohide={5000}
                    fade={true}
                    className={toast.type}
                  >
                    <CToastHeader closeButton={true}>
                    {i18n.t('Thông báo')}
                    </CToastHeader>
                    <CToastBody>
                      {toast.message}
                    </CToastBody>
                  </CToast>
                )
              })
            }
          </CToaster>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route path="/" name="Home" render={props => <TheLayoutWarranty {...props}/>} />
              <Route path="*" name="Page 404" render={props => <Page404 {...props}/>} />
            </Switch>
          </React.Suspense>
      </HashRouter>
    );
  }
}
function mapState(state) {
  const { alert } = state;
  return { alert };
}

const actionCreators = {
  clearAlerts: alertActions.clear
};
const connectedApp = connect(mapState, actionCreators)(App);
export default connectedApp;
//withTranslation()(connectedApp);
