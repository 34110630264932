import { store } from 'src/_helpers'
import { alertActions } from 'src/_actions'
import { mesageConstants } from 'src/_constants'
import { VIcon } from 'src/_components'
export const CopyToClipboard = ({ children }) => {
  const onCopyToClipboard = () => {
    if (children) {
      const el = document.createElement('textarea')
      el.value = children
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-9999px'
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
      store.dispatch(alertActions.success(mesageConstants.COPYED))
    }
  }
  return (
    <>
      <span className="copy-to-clipboard pointer" onTouchStart={() => onCopyToClipboard()} onDoubleClick={() => onCopyToClipboard()}>{children} <VIcon size={'sm'} name='cilCopy' /></span>
    </>
  )
}