import { connect } from 'react-redux';
const NetworkAction = (props) => {
  if (!props.networkId) return ''
  if (props.type === 'owner' && !props.user.network_owners.includes(Number(props.networkId))) return ''
  if (props.type === 'member' && !props.user.network_members.includes(Number(props.networkId))) return ''
  return (
    <>
      {props.children}
    </>
  )
}

function mapState(state) {
  const { user } = state.authentication;
  return { user };
}
export const NetworkActionWrapper = connect(mapState)(NetworkAction);

