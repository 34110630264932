const initialState = {
  value: 'responsive'
}
export const sidebarShow = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return {...state, ...rest }
    default:
      return state
  }
}

