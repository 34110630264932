export * from './alert.constants';
export * from './user.constants';
export * from './mesage.constants';
export * from './his.constants';
export * from './his.constants';
export * from './his.constants';
export * from './conditionType.constants';
export * from './validator.message';
export const storeInvoiceType = [
  'Phiếu nhập hàng', 'Phiếu xuất hàng', 'Phiếu điều chuyển', 'Phiếu hủy', 'Phiếu tạo tự động'
]
export const paymentTypes = [
  'Tiền Mặt', 'Chuyển Khoản', 'COD', 'Công Nợ'
]
export const orderStatus = [
  'Tạo mới', 'Đã gửi, Chờ duyệt', 'Đã duyệt', 'Đang giao', 'Đã nhận', 'Đã hủy'
]
export const orderType = [
  'Đơn bán hàng', 'Đơn đặt hàng', 'Đơn đặt hàng khách lẻ', 'Trả hàng', 'Đơn bán hàng khách lẻ',
]
export const submenuConstants = {
  ADD: 'ADD_MENU',
  NEW: 'NEW_MENU',
  CLEAR: 'CLEAR'
};
export const transactionType = [
  {value: '', label: '-- Chọn --'},
  {value: '0', label: 'Doanh thu'},
  {value: '1', label: 'Chi phí nhập hàng'},
  {value: '2', label: 'Chi phí ship'},
  {value: '3', label: 'Thuế'},
  {value: '4', label: 'Nợ'},
  {value: '5', label: 'Trả nợ'},
]
export const orderStatusOption = [
  {value: '', label: 'Tất cả'},
  {value: '1', label: 'Tạo mới'},
  {value: '2', label: 'Đã duyệt'},
  {value: '3', label: 'Đang giao'},
  {value: '4', label: 'Đã nhận'},
  {value: '5', label: 'Đã Hủy'}
]