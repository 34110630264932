import React from "react";
import Autocomplete from "react-autocomplete";
import { CustomerService } from "src/_services";

export class CustomerAutocomplete extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customers: [],
      value: ""
    };
  }
  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.setValue();
    }
  }
  componentDidMount(prevProps) {
    this.setValue();
  }
  setValue() {
    this.setState({ value: this.props.value });
  }
  onSelect(value, item) {
    this.setState({ value });
    if (this.props.onSelect) {
      this.getCustomer(value, item)
    };
    if (this.props.onChange) this.props.onChange({
      target: {
        name: this.props.name,
        value: value
      }
    });
  }
  getCustomer(value, item) {
    new CustomerService().noLoading().noError()
      .find(item.id)
      .then(formData => {
        this.props.onSelect(value, formData)
      });
  }
  onChange(event, value) {
    this.setState({ value });
    this.getData(value);
    if (this.props.onChange) this.props.onChange({
      target: {
        name: this.props.name,
        value: value
      }
    });
  }
  getData(search) {
    if (this.props.AutocompleteOff) return true
    new CustomerService({ search }).noLoading().noError().all().then(response => {
      this.setState({
        customers: response.results.map(e => {
          e.abbr = e.id;
          e.name = e.full_name;
          return e;
        })
      });
    });
  }
  render() {
    return (
      <Autocomplete
        inputProps={{ className: 'form-control' }}
        wrapperStyle={{ position: "relative", zIndex: 2 }}
        // open={true}
        value={this.state.value}
        items={this.state.customers}
        getItemValue={item => item.full_name}
        onSelect={(value, item) => this.onSelect(value, item)}
        onChange={(event, value) => this.onChange(event, value)}
        renderItem={(item, isHighlighted) => (
          <div
            className={`autocomplete-item ${
              isHighlighted ? "item-highlighted" : ""
            }`}
            key={item.id}
          >
            <p>{item.full_name} - {item.phone_no_1}</p>
            <p className="text-muted"><i>{[item.address, item.district, item.province].filter(e => e).join(', ')}</i></p>
          </div>
        )}
        renderMenu={children => this.state.customers.length > 0 ? <div className="autocomplete-menu">{children}</div> : <></>}
      />
    );
  }
}
