import React from "react";
import { VIcon } from "src/_components";
// import catDataDemo from "src/_constants/cat.demo";
import clsx from "clsx";
import { CBreadcrumb, CBreadcrumbItem, CLink, CInput} from "@coreui/react";
import { CategoryService } from "src/_services";
import { slugify } from "src/_helpers";
export class CategoriesSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Categories: [],
      paths: [[], [], [], [], [], [], [], [], []],
      selected: [null, null, null, null, null, null, null, null, null],
      hasSelect: false,
      filterText: '',
      filteredId: []
    };
    this.select = this.select.bind(this);
    this.openForm = this.openForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {
    this.getData();
  }
  handleChange(e) {
    const { value } = e.target;
    this.setState({
      filterText: slugify(value)
    })
    setTimeout(() => {
      this.setPathStrForFilter()
    }, 100)
  }
  setPathStrForFilter() {
    let filtered = this.state.Categories.filter(e => e.searchText.includes(this.state.filterText))
    var arrId = []
    filtered.forEach(e => {
      arrId = [...arrId, ...e.paths]
    })
    this.setState({
      filteredId: arrId
    })
  }
  getData() {
    new CategoryService({
      page_size: 9999,
      page: 1
    })
      .noLoading()
      .all()
      .then(res => {
        var rootCategory = (this.props.rootCategory || []).filter(e => e).map(e => e.id)
        let dataCate = res.results.map(e => {
          e.has_children =
            res.results.filter(f => f.parent_id === e.id).length > 0;
          return e;
        });
        this.setState({ Categories: dataCate });
        this.setState({
          paths: [
            dataCate.filter(e => !e.parent_id && rootCategory.includes(e.id)),
            [],
            [],
            [],
            [],
            [],
            [],
            [],
            []
          ]
        });
        this.buildValue();
      });
  }
  buildValue() {
    let id = this.props.value;
    var rootCategory = (this.props.rootCategory || []).filter(e => e).map(e => e.id)
    let selectedCat = this.state.Categories.find(f => f.id === id);
    if (selectedCat) {
      let arr = [null, null, null, null, null, null, null, null, null];
      let selected = [selectedCat, null, null, null, null, null];
      arr.forEach((e, i) => {
        let lastObj = selected[0];
        if (i && lastObj && lastObj.parent_id) {
          selected.unshift(
            this.state.Categories.find(f => lastObj.parent_id === f.id)
          );
        }
      });
      this.setState({ selected });
      let { paths } = this.state;
      selected.forEach((item, index) => {
        if (item)
          if (index === 0) {
            paths[index] = this.state.Categories.filter(
              f => item.parent_id === f.parent_id && rootCategory.includes(f.id)
            );
          } else {
            paths[index] = this.state.Categories.filter(
              f => item.parent_id === f.parent_id
            );
          }
      });
      this.setState({ paths });
    }
  }
  openForm() {
    this.setState({ hasSelect: true });
  }
  select(e, level) {
    var { paths, selected } = this.state;
    var next = level + 1;
    if (e.has_children) {
      paths[next] = this.getChil(e);
      paths = paths.map((path, index) => {
        if (index > next) return [];
        return path;
      });
      this.setState({ paths });
    }
    selected = selected.map((path, index) => {
      if (index >= next) return null;
      return path;
    });
    selected[level] = e;
    this.setState({ selected });
    setTimeout(() => {
      this.onChangeEvent();
    }, 100);
  }
  onChangeEvent() {
    const { selected } = this.state;
    let arr = selected.filter(f => f);
    if (this.props.onChange)
      this.props.onChange({
        target: {
          name: this.props.name,
          value: arr[arr.length - 1].id
        }
      });
  }
  getChil(parent) {
    return this.state.Categories.filter(e => e.parent_id === parent.id);
  }
  availableWithFilter(paths) {
    if (!this.state.filterText) return false
    return this.state.filteredId.find(e => paths.includes(e))
  }
  selectForm() {
    const { paths, selected, filterText } = this.state;
    return (
      <>
        <div className={clsx({
          "category-list": true,
          'has-filter': filterText
        })}>
          {paths
            .filter(f => f && f.length)
            .map((data, i) => {
              return (
                <ul className="scroll-item" key={i}>
                  {data.map(e => {
                    var active = selected.find(
                      item => item && item.id === e.id
                    );
                    var availableWithFilter = this.availableWithFilter(e.paths)
                    return (
                      <li
                        key={e.id}
                        className={clsx({
                          "category-item": true,
                          active: active,
                          'available-with-filter': availableWithFilter
                        })}
                      >
                        <p
                          className="text-overflow"
                          onClick={() => this.select(e, i)}
                        >
                          {e.name} - {e.paths.join(',')}
                          {e.has_children && <VIcon name="cilChevronRight" />}
                          {!e.has_children && active && (
                            <VIcon name="cilCheck" />
                          )}
                        </p>
                      </li>
                    );
                  })}
                </ul>
              );
            })}
        </div>
      </>
    );
  }
  filterForm() {
    return (
      <CInput
        type="text"
        name="filter"
        placeholder="Nhập để tìm kiếm"
        onChange={this.handleChange}
      />
    )
  }
  breadcrumb() {
    const { selected } = this.state;
    return (
      <>
          <CBreadcrumb className="v-breadcrumb">
            {selected
              .filter(f => f)
              .map(e => {
                return (
                  <CBreadcrumbItem key={e.id}>
                    <CLink>
                      {e.name}{" "}
                      {!e.has_children && !this.props.itemName && (
                        <VIcon name="cilCheck" />
                      )}
                    </CLink>
                  </CBreadcrumbItem>
                );
              })}
            {this.props.itemName && (
              <CBreadcrumbItem>
                <CLink>{this.props.itemName}</CLink>
              </CBreadcrumbItem>
            )}
          </CBreadcrumb>
      </>
    );
  }
  render() {
    const { hasSelect } = this.state;
    if (this.props.readonly) return <>{this.breadcrumb()}</>;
    return (
      <div className="categories-select">
        {hasSelect ? (
          <>
            {this.filterForm()}
            {this.selectForm()}
          </>
        ) : (
            <>
              <button onClick={() => this.openForm()} className="btn link">
                Chọn danh mục <VIcon name="cilPencil" />
              </button>
            </>
          )}
        {this.breadcrumb()}
      </div>
    );
  }
}
