import React, { Component } from "react";
import { PriceService } from "src/_services";
import {
  CurrencyText
} from "src/_components";
import moment from "moment-timezone";
import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CFormGroup,
  CLabel,
  CTabs,
  CTabContent,
  CTabPane,
  CNav,
  CNavItem,
  CNavLink,
  CInputGroup,
  CInputGroupAppend,
  CBadge
} from "@coreui/react";
import { conditionType, discountType, paymentType } from "src/_constants";
// const fields = [
//   { key: "name", label: "Tên" },
//   { key: "base_price", label: "Giá"},
//   { key: "from_date", label: "Từ"},
//   { key: "to_date", label: "Đến"},
//   { key: "condition_type_name", label: "Condition"},
//   { key: "discount_type_name", label: "Discount"},
//   { key: "payment_type_name", label: "Payment"}
// ];
export class PriceTableView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasForm: false,
      formData: null,
      priceId: props.id
    };
  }
  closeForm() {
    this.setState({ hasForm: false });
  }
  openForm() {
    let { formData, priceId } = this.state;
    if (formData) {
      this.setState({ hasForm: true });
    } else {
      this.getDetail(priceId);
    }
  }
  getDetail(id) {
    new PriceService().find(id).then(res => {
      this.setState({
        formData: {
          ...res,
          time: {
            start: moment(
              res.from_date,
              process.env.REACT_APP_PYTHON_DATE_FORMAT
            ).format(process.env.REACT_APP_DATE_FORMAT),
            end: moment(
              res.to_date,
              process.env.REACT_APP_PYTHON_DATE_FORMAT
            ).format(process.env.REACT_APP_DATE_FORMAT)
          }
        }
      });
      this.setState({ hasForm: true });
    });
  }
  modal () {
    const { hasForm, formData } = this.state;
    if (!formData) return '';
    let condition_type = conditionType.find(
      e => e.value === String(formData.condition_type)
    );
    let discount_type = discountType.find(
      e => e.value === String(formData.discount_type)
    );
    let payment_type = paymentType.find(
      e => e.value === String(formData.payment_type)
    );
    return (
      <>
        <CModal
          show={hasForm}
          onClose={() => this.closeForm()}
          color="primary"
          size="lg"
          className="text-left"
        >
          <CModalBody>
            {hasForm && (
              <>
                <CTabs>
                  <CNav variant="tabs">
                    <CNavItem>
                      <CNavLink>Thông tin chung</CNavLink>
                    </CNavItem>
                    <CNavItem>
                      <CNavLink>Bảng giá</CNavLink>
                    </CNavItem>
                  </CNav>
                  <CTabContent>
                    <CTabPane className="p10">
                      <CFormGroup>
                        <CLabel className="pr-10">Tên:</CLabel>
                        {formData.name}
                      </CFormGroup>
                      <CFormGroup>
                        <CLabel className="pr-10">Thời gian áp dụng:</CLabel>
                        {formData.from_date} - {formData.to_date}
                      </CFormGroup>
                      <CFormGroup>
                        <CLabel className="pr-10">Giá bán lẻ:</CLabel>
                        <CurrencyText value={formData.base_price} />
                      </CFormGroup>
                      <CFormGroup>
                        <CLabel className="pr-10">Áp dụng theo:</CLabel>
                        {condition_type ? condition_type.label : ""}
                      </CFormGroup>
                      <CFormGroup>
                        <CLabel className="pr-10">Giảm giá:</CLabel>
                        {discount_type ? discount_type.label : ""}
                      </CFormGroup>
                      <CFormGroup>
                        <CLabel className="pr-10">Loại thanh toán:</CLabel>
                        {payment_type ? payment_type.label : ""}
                      </CFormGroup>
                    </CTabPane>
                    <CTabPane>{this.itemPriceDetail()}</CTabPane>
                  </CTabContent>
                </CTabs>
              </>
            )}
          </CModalBody>
          <CModalFooter>
            <CButton color="primary" onClick={() => this.closeForm(false)}>
              Đóng
            </CButton>
          </CModalFooter>
        </CModal>
      </>
    );
  }
  render() {
    return (
      <>
        <CBadge color="info" onClick={() => this.openForm()}>Chính sách giá</CBadge>
        {this.modal()}
      </>
    );
  }
  itemPriceDetail() {
    const { details } = this.state.formData;
    return (
      <table className="table table-bordered text-left" size="sm">
        <thead className="thead-dark">
          <tr>
            <th>Số lượng từ</th>
            <th>Đến</th>
            <th>Giảm giá</th>
          </tr>
        </thead>
        <tbody>
          {details.map((item, index) => {
            return (
              <tr key={index}>
                <th>{item.from_quantity}</th>
                <th>{item.to_quantity}</th>
                <th>
                  <CFormGroup>
                    <CInputGroup>
                      <CurrencyText
                        value={item.discount}
                      />
                      <CInputGroupAppend>
                        {item.is_discount_percent ? ' %' : ' VNĐ' }
                      </CInputGroupAppend>
                    </CInputGroup>
                  </CFormGroup>
                </th>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
}
