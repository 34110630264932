import React, { useState, forwardRef } from "react";
import DatePicker from "react-datepicker";
import moment from "moment-timezone";
import { VIcon } from 'src/_components'
import {
  isMobile
} from "react-device-detect";
const format = process.env.REACT_APP_DATE_FORMAT
export const VDatePicker = (props) => {
  const [value, setValue] = useState(props.value ? moment(props.value, format).toDate() : null);
  const applyCallback = (val) => {
    setValue(val)
    if (props.onChange)
      props.onChange({
        target: {
          name: props.name,
          value: val ? moment(val).format(format) : ''
        }
      });
  };
  const DateInput = forwardRef(
    ({ value, onClick }, ref) => (
      <div className="custom-input" onClick={onClick} ref={ref}>
        <VIcon size={'sm'} name='cilCalendar' /> {value ? value : (props.placeholder || 'Chọn ngày')}
      </div>
    ),
  );
  return (
    <DatePicker
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
      className="form-control"
      selected={value}
      onChange={date => applyCallback(date)}
      dateFormat={'dd/MM/yyyy'}
      isClearable
      placeholderText={props.placeholder || 'Chọn ngày'}
      placeholder={props.placeholder || 'Chọn ngày'}
      maxDate={props.maxDate}
      minDate={props.minDate}
      withPortal={isMobile}
      customInput={<DateInput />}
    />
  );
};