import clsx from "clsx";
import { Loading } from "src/_components";
export const Avata = (props) => {
  return (
    <div
      className={clsx({
        "a-avatar": true,
      })}
      {...props}
    >
      <div className="image-loading position-center">
        <Loading type="ring" />
      </div>
      <img
        className={clsx({
          "position-center": true,
        })}
        src={props.src || "/static/images/no-img.png"}
        alt=""
      />
      <img
        className={clsx({
          "image-placeholder": true,
        })}
        src={"/static/images/no-img.png"}
        alt=""
      />
    </div>
  );
};
