export const conditionType = [
  // {value: '0', label: 'Áp dụng theo cấp đại lý'},
  {value: '1', label: 'Áp dụng theo doanh số (Tiền)'},
  {value: '2', label: 'Áp dụng theo số lượng sản phẩm bán được'}
]
export const discountType = [
  {value: '0', label: 'Giảm phần trăm trên giá'},
  {value: '1', label: 'Giảm tiền trực tiếp trên giá'}
]
export const paymentType = [
  {value: '0', label: 'Trừ thẳng vào hóa đơn'},
  {value: '1', label: 'Cộng vào tài khoản chiết khấu thanh toán sau'},
  {value: '2', label: 'Voucher cho lần mua sau'}
]
