import React, { Component } from 'react';
import { confirmAlert } from 'react-confirm-alert'
import { Loading, Avata, VIcon, Uploader } from 'src/_components'
import { UploadService } from 'src/_services'
import PhotoGrid from 'src/_components/PhotoGrid'
import clsx from 'clsx'
import {
  CButton,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CTabs,
  CTabContent,
  CTabPane,
  CNav,
  CNavItem,
  CNavLink,
  CCardFooter,
  CPagination
} from "@coreui/react"
import { isJson } from 'src/_helpers'

import 'react-bnb-gallery/dist/style.css';

export class MediaLibrary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      activeTab: 0,
      results: null,
      currentValues: [],
      myPhotos: [],
      selected: [],
      pages: 1,
      query: {
        page: 1,
        page_size: Number(process.env.REACT_APP_PAGE_SIZE)
      }
    };
    this.setModal = this.setModal.bind(this);
    this.setActiveTab = this.setActiveTab.bind(this);
    this.setSelected = this.setSelected.bind(this);
    this.applyCallbackFiles = this.applyCallbackFiles.bind(this);
    this.applyCallbackFilesFromLib = this.applyCallbackFilesFromLib.bind(this);
    this.removeAll = this.removeAll.bind(this)

    this.PhotoGridRef = React.createRef();
  }
  componentDidMount() {
    this.setCurrentValues();
  }
  removeAll() {
    confirmAlert({
      title: 'Confirm to Remove All',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.removeFile()
        },
        {
          label: 'No'
        }
      ]
    })
  }
  removeClick(file) {
    confirmAlert({
      title: 'Confirm to Remove',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.removeFile(file)
        },
        {
          label: 'No'
        }
      ]
    });
  }
  removeFile(file) {
    if (file) {
      var currentValues = this.state.currentValues.filter(e => e !== file)
      this.setState({ currentValues })
    } else {
      this.setState({ currentValues: [] })
    }
    setTimeout(() => {
      this.onChangeEvent()
    }, 100);
  }
  setSelected(selected) {
    this.setState({ selected })
  }
  setCurrentValues() {
    var { currentValues } = this.state
    var files;
    if (this.props.value) {
      if (typeof this.props.value === 'object') {
        files = this.props.value || []
      }
      else if (isJson(this.props.value)) {
        files = JSON.parse(this.props.value)
      }
      else {
        files = [this.props.value]
      }
    }
    this.setState({
      currentValues: currentValues.concat(files || [])
    })
  }
  getData() {
    new UploadService(this.state.query).all().then(img => {
      img.results.forEach(async e => {
        e.url = e.url.replace('&export=download', '')
        e.src = e.url
        e.selected = false
      })
      var bonus = 1;
      if (img.count % this.state.query.page_size === 0) {
        bonus = 0;
      }
      this.setState({
        pages: parseInt(img.count / this.state.query.page_size) + bonus
      });
      this.setState({ myPhotos: img.results })
    })
  }
  setActiveTab(activeTab) {
    if (activeTab) this.getData()
    this.setState({ activeTab })
  }
  setModal(status) {
    this.setState({ isOpen: status })
    // if (status) this.getData()
  }
  onActiveTabChange(a, b) {
  }
  applyCallbackFilesFromLib() {
    const { selected } = this.state

    this.applyCallbackFiles(selected)
  }
  applyCallbackFiles(files) {
    const { currentValues } = this.state
    if (this.props.multiple) {
      this.setState({
        currentValues: currentValues.concat(files)
      })
    } else {
      this.setState({
        currentValues: (files)
      })
    }
    this.setModal()
    setTimeout(() => {
      this.onChangeEvent()
    }, 100)
  }
  pageChange(newPage, oldpage) {
    if (this.state.query.page !== newPage && !oldpage) {
      this.updateStateQuery('page', newPage)
    }
  }
  updateStateQuery(name, value) {
    var query = { ...this.state.query }
    query[name] = value
    this.setState({ query })
    setTimeout(() => {
      this.getData()
    }, 100)
  }
  onChangeEvent() {
    if (this.props.onChange) this.props.onChange(this.state.currentValues, this.props.name)
  }
  modal() {
    const { isOpen, activeTab, myPhotos, selected, pages, query } = this.state
    return (
      <CModal
        show={isOpen}
        onClose={() => this.setModal()}
        size="lg"
        color="primary"
      >
        <CModalHeader closeButton>
          <CModalTitle>Upload/ Chọn ảnh</CModalTitle>
        </CModalHeader>
        <CModalBody className="v-tabs">
          <CTabs onActiveTabChange={() => this.onActiveTabChange}>
            <CNav variant="tabs">
              <CNavItem>
                <CNavLink onClick={() => this.setActiveTab(0)}>Upload</CNavLink>
              </CNavItem>
              <CNavItem>
                <CNavLink onClick={() => this.setActiveTab(1)}>Thư viện</CNavLink>
              </CNavItem>
            </CNav>
            <CTabContent>
              <CTabPane visible={String(activeTab === 0)} className="pt-2 pb-2">
                <Uploader
                  applyCallback={this.applyCallbackFiles}
                  name="image"
                  value={[]}
                  multiple={this.props.multiple}
                  mediaLibraryMode={true}
                />
              </CTabPane>
              <CTabPane visible={String(activeTab === 1)}>
                {activeTab === 1 && <PhotoGrid multiple={this.props.multiple} photos={myPhotos} setSelected={this.setSelected} />}
                {pages > 1 &&
                    <CPagination
                      className="mt-2"
                      activePage={query.page}
                      onActivePageChange={this.pageChange.bind(this)}
                      pages={pages}
                      doubleArrows={true}
                      align="start"
                    />
                  }
              </CTabPane>
            </CTabContent>
          </CTabs>
        </CModalBody>
        <CCardFooter className="d-flex justify-content-between">
          <CButton
            color="secondary"
            onClick={() => this.setModal()}
          >Hủy</CButton>
          {(selected.length > 0 && activeTab === 1) &&
            <CButton
              color="success"
              onClick={this.applyCallbackFilesFromLib}
            >Chọn ({selected.length})</CButton>
          }
        </CCardFooter>
      </CModal>
    )
  }
  inputValue() {
    const { currentValues } = this.state
    if (!currentValues) return <Loading />
    return (
      <>
        <div className={clsx({
          'input-media': true,
          hasFiles: currentValues && currentValues.length,
          single: !this.props.multiple
        })}>
          {currentValues.map((e, index) => {
            return (<div key={index} className="input-media-wrap">
              <Avata src={e} />
              <CButton onClick={() => this.removeClick(e)}><VIcon size={'sm'} name='cilXCircle' /></CButton>
            </div>)
          })}
          <div className="input-placeholder">
            <div className="input-media-placeholder" onClick={() => this.setModal(true)}>
              <img className="image-placeholder" src="/static/images/no-img.png" alt="" />
              <VIcon size={'xl'} name='cilImagePlus' />
            </div>
          </div>
        </div>
        {(currentValues && currentValues.length > 1) ? <p className="pointer" onClick={() => this.removeAll()}> Clear all</p> : ''}
      </>
    )
  }
  render() {
    const { isOpen } = this.state
    return (
      <>
        {this.inputValue()}
        {isOpen && this.modal()}
      </>
    )
  }
}