import React, { Component } from "react";
import { PriceService } from "src/_services";
import {
  Loading,
  NetworkActionWrapper,
  CurrencyText,
  VIcon
} from "src/_components";
import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CFormGroup,
  CDataTable,
  CLabel,
  CTabs,
  CTabContent,
  CTabPane,
  CNav,
  CNavItem,
  CNavLink,
  CInputGroup,
  CInputGroupAppend
} from "@coreui/react";
import {
  BrowserView,
  MobileView
} from "react-device-detect";
import { conditionType, discountType, paymentType } from "src/_constants";
import { pythonDateToVnDate, vnDateTopythonDate, history } from "src/_helpers"
export class PriceTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: false,
      hasForm: false,
      formData: {
      },
      editItem: {},
      detailData: null
    };
    this.handleChange = this.handleChange.bind(this);
    this.openForm = this.openForm.bind(this);
    this.closeForm = this.closeForm.bind(this);
    this.save = this.save.bind(this);
    this.handleSelectInputChange = this.handleSelectInputChange.bind(this);
    this.pushToDetails = this.pushToDetails.bind(this);
    this.handleDetailsChange = this.handleDetailsChange.bind(this);
    this.removeItem = this.removeItem.bind(this);
  }
  componentDidMount() {
    this.getData();
  }
  removeItem(item) {
    let { formData } = this.state;
    let currentItems = formData.details.filter(e => e.id !== item.id);
    formData.details = currentItems;
    this.setState({ formData });
  }
  handleDetailsChange(e, index) {
    const { name, value, localName } = e.target;
    let { formData } = this.state;
    let currentItems = formData.details;
    currentItems[index][name] = localName === 'select' ? (value === true || value === 'true') : value;
    formData.details = currentItems;
    this.setState({ formData });
  }
  pushToDetails() {
    let { formData } = this.state
    formData.details.push({
      from_quantity: 1,
      to_quantity: 10,
      discount: 0,
      is_discount_percent: false
    })
    this.setState({ formData });
  }
  create() {
    new PriceService({ item_id: this.props.itemData.id })
      .create({
        ...this.state.formData,
        from_date: vnDateTopythonDate(
          this.state.formData.time.start),
        to_date: vnDateTopythonDate(
          this.state.formData.time.end)
      })
      .then(() => {
        this.getData();
        this.closeForm()
      });
  }
  update() {
    new PriceService({ item_id: this.props.itemData.id })
      .update(this.state.formData.id, {
        ...this.state.formData,
        from_date: vnDateTopythonDate(
          this.state.formData.time.start
        ),
        to_date: vnDateTopythonDate(
          this.state.formData.time.end
        )
      })
      .then(() => {
        this.getData();
        this.closeForm()
      });
  }
  save() {
    if (this.state.formData.id) {
      this.update()
    } else {
      this.create()
    }
  }
  closeForm() {
    this.setState({ hasForm: false });
  }
  openForm(editItem) {
    history.push(`/network/${this.props.itemData.network.id}/network-items/${this.props.itemData.id}/price-form/${editItem ? editItem.id: ''}`)
  }
  getDetail(id) {
    new PriceService()
      .find(id)
      .then(res => {
        this.setState({
          detailData: {
            ...res,
            time: {
              start: pythonDateToVnDate(res.from_date),
              end: pythonDateToVnDate(res.to_date)
            }
          }
        })
        this.setState({ hasForm: true });
      });
  }
  getData() {
    new PriceService({
      item_id: this.props.itemData.id,
      type: this.props.type,
      page_size: 9999,
      page: 1
    })
      .all()
      .then(res => {
        this.setState({ data: res.results.map(e => {
          e.from_date = pythonDateToVnDate(e.from_date)
          e.to_date = pythonDateToVnDate(e.to_date)
          return e
        }) });
      });
  }
  handleChange(e) {
    let { value, name, type, checked } = e.target;
    this.setState(prevState => {
      let formData = Object.assign({}, prevState.formData);
      formData[name] = type === 'checkbox' ? checked : value;
      return { formData };
    });
  }
  handleSelectInputChange(value, input) {
    this.setState(prevState => {
      let formData = Object.assign({}, prevState.formData);
      formData[input.name] = value.value;
      return { formData };
    });
  }
  editBtn(item) {
    if (this.props.type === "parent") return (<CButton
      className="btn"
      onClick={() => this.getDetail(item.id)}
      type="button"
      color="warning"
      size={"sm"}
    >
      Xem
    </CButton>)
    if (this.props.type === "owner") return (<CButton
      className="btn"
      onClick={() => this.openForm(item)}
      type="button"
      color="warning"
      size={"sm"}
    >
      Sửa
    </CButton>)
    if (this.props.type === "self") return (<CButton
      className="btn"
      onClick={() => this.openForm(item)}
      type="button"
      color="warning"
      size={"sm"}
    >
      Sửa
    </CButton>)
    return ""
  }
  createBtn() {
    return (
      <>
        {this.props.type === "parent" || this.props.type === "owner" ? (
          <NetworkActionWrapper
            type="owner"
            networkId={this.props.itemData.network.id}
          >
            <CButton
              className="btn-block"
              onClick={() => this.openForm()}
              type="button"
              color="primary"
              size={"sm"}
            >
              Tạo mới
            </CButton>
          </NetworkActionWrapper>
        ) : (
            <NetworkActionWrapper
              type="member"
              networkId={this.props.itemData.network.id}
            >
              <CButton
                className="btn-block"
                onClick={() => this.openForm()}
                type="button"
                color="primary"
                size={"sm"}
              >
                Tạo mới
            </CButton>
            </NetworkActionWrapper>
          )}
      </>
    );
  }
  status (item) {
    return (<>
      {item.is_active ? <span className="font-weight-bold text-success"><VIcon name="cilCheck" /></span> : <span className="font-weight-bold text-danger"><VIcon name="cilXCircle" /></span>}
    </>)
  }
  render() {
    const { data } = this.state;
    if (!data) return <Loading />;
    return (
      <>
        <BrowserView>
        <CDataTable
          items={data}
          fields={[
            { key: "name", label: "Tên" },
            { key: "base_price", label: "Giá bán lẻ" },
            { key: "detailsx", label: "Chi tiết" },
            { key: "is_active", label: "Trạng thái",  _style: { width: '1%',whiteSpace: 'nowrap' },},
            {
              key: 'show_details',
              label: '',
              _style: { width: '1%' },
              sorter: false,
              filter: false
            }
          ]}
          hover
          striped
          bordered
          size="sm"
          itemsPerPage={10}
          pagination
          scopedSlots={{
            'detailsx':
              (item) => (
                <td>
                  <div className="mb-1">- {item.from_date} đến {item.to_date}</div>
                  <div className="mb-1">- {item.condition_type_name}</div>
                  <div className="mb-1">- {item.discount_type_name}</div>
                  <div className="mb-1">- {item.payment_type_name}</div>
                </td>
              ),
            'show_details':
              (item) => (
                <td>
                  {this.editBtn(item)}
                </td>
              ),
              'date':
              (item) => (
                <td>
                  {item.from_date} đến {item.to_date}
                </td>
              ),
            'is_active':
            (item) => (
              <td className="text-center">
                {this.status(item)}
              </td>
            ),
            'base_price':
              (item) => (
                <td>
                  <CurrencyText value={item.base_price} />
                </td>
              )
          }}
        />
        </BrowserView>
        <MobileView>
        <div className="invoice-mobile-list mb-2">
            {(data || []).map((item) => {
              return (<div
                // onClick={() => props.history.push(`/network/${props.match.params.networkId}/invoice-view/${item.id}`)}
                key={item.index} className="d-flex justify-content-between m-invoice-item">
                <div>
                  <div className="font-weight-bold">#{String(item.id).padStart(5, '0')}</div>
                  <div className="font-weight-bold">{item.name}</div>
                  <div>Hiệu lực từ {item.from_date} đến {item.to_date}</div>
                  <div>{this.editBtn(item)}</div>
                </div>
                <div className="text-right">
                  <p>{this.status(item.is_active)}</p>
                  <h4><CurrencyText value={item.base_price} /></h4>
                </div>
              </div>)
            })}
            {!data || data.length === 0 ? <div className="d-flex justify-content-center m-invoice-item">Không có kết quả</div> : ''}
          </div>
        </MobileView>
        {this.createBtn()}
        {this.modalView()}
      </>
    );
  }
  modalView () {
    const { hasForm, detailData } = this.state;
    if (!detailData) return '';
    let condition_type = conditionType.find(
      e => e.value === String(detailData.condition_type)
    );
    let discount_type = discountType.find(
      e => e.value === String(detailData.discount_type)
    );
    let payment_type = paymentType.find(
      e => e.value === String(detailData.payment_type)
    );
    return (
      <>
        <CModal
          show={hasForm}
          onClose={() => this.closeForm()}
          color="primary"
          size="lg"
          className="text-left"
        >
          <CModalBody>
            {hasForm && (
              <>
                <CTabs>
                  <CNav variant="tabs">
                    <CNavItem>
                      <CNavLink>Thông tin chung</CNavLink>
                    </CNavItem>
                    <CNavItem>
                      <CNavLink>Bảng giá</CNavLink>
                    </CNavItem>
                  </CNav>
                  <CTabContent>
                    <CTabPane className="p10">
                      <CFormGroup>
                        <CLabel className="pr-10">Tên:</CLabel>
                        {detailData.name}
                      </CFormGroup>
                      <CFormGroup>
                        <CLabel className="pr-10">Thời gian áp dụng:</CLabel>
                        {detailData.from_date} - {detailData.to_date}
                      </CFormGroup>
                      <CFormGroup>
                        <CLabel className="pr-10">Giá bán lẻ:</CLabel>
                        <CurrencyText value={detailData.base_price} />
                      </CFormGroup>
                      <CFormGroup>
                        <CLabel className="pr-10">Áp dụng theo:</CLabel>
                        {condition_type ? condition_type.label : ""}
                      </CFormGroup>
                      <CFormGroup>
                        <CLabel className="pr-10">Giảm giá:</CLabel>
                        {discount_type ? discount_type.label : ""}
                      </CFormGroup>
                      <CFormGroup>
                        <CLabel className="pr-10">Loại thanh toán:</CLabel>
                        {payment_type ? payment_type.label : ""}
                      </CFormGroup>
                    </CTabPane>
                    <CTabPane>{this.itemPriceDetail()}</CTabPane>
                  </CTabContent>
                </CTabs>
              </>
            )}
          </CModalBody>
          <CModalFooter>
            <CButton color="primary" onClick={() => this.closeForm(false)}>
              Đóng
            </CButton>
          </CModalFooter>
        </CModal>
      </>
    );
  }
  itemPriceDetail() {
    const { details } = this.state.detailData
    return (
      <table className="table table-bordered text-left" size="sm">
        <thead className="thead-dark">
          <tr>
            <th>Số lượng từ</th>
            <th>Đến</th>
            <th>Giảm giá</th>
          </tr>
        </thead>
        <tbody>
          {details.map((item, index) => {
            return (
              <tr key={index}>
                <th>{item.from_quantity}</th>
                <th>{item.to_quantity}</th>
                <th>
                  <CFormGroup>
                    <CInputGroup>
                      <CurrencyText
                        value={item.discount}
                      />
                      <CInputGroupAppend>
                        {item.is_discount_percent ? ' %' : ' VNĐ' }
                      </CInputGroupAppend>
                    </CInputGroup>
                  </CFormGroup>
                </th>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
}
