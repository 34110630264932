import React, { useState } from "react";
import {
  CInput,
  CInputGroup, CInputGroupAppend, CInputGroupText
} from '@coreui/react'
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import {
  isMobileOnly
} from "react-device-detect";
// import Barcode from 'react-barcode';
export const InputText = (props) => {
  const [isOpen, setCamera] = useState(false);
  return (
    <>
      {props.barcode && isMobileOnly ?
      <>
        {isOpen && <div className="BarcodeScanner" onClick={() => setCamera(false)}>
        <BarcodeScannerComponent
          width={500}
          height={500}
          onUpdate={(err, result) => {
            if (result) {
              // this.setState({result: result.text})
              // this.openForm()
              // history.push('/warranty?serial=' + result.text)
              console.log(result)
              if (props.onChange)
                props.onChange({
                  target: {
                    name: props.name,
                    value: result.text
                  }
                });
              setCamera(false)
            }
            else console.log('Not Found')
          }}
        /></div>
        }
        <CInputGroup>
          <CInput autoComplete="off" {...props} />
          <CInputGroupAppend>
            <CInputGroupText className={'bg-info text-white'} onClick={() => setCamera(true)}>
              <span className="fa fa-barcode"></span>
            </CInputGroupText>
          </CInputGroupAppend>
        </CInputGroup>
      </> :
        <CInput autoComplete="off" {...props} />
      }
    </>
  )
}