import { store } from 'src/_helpers'
import moment from "moment-timezone";
export function go2Login() {
  window.location.hash = '#/login'
}
export function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0,
      v = c === 'x' ? r : ((r & 0x3) | 0x8);
    return v.toString(16);
  });
}
export function isValidHttpUrl(str) {
  if (!str) return false
  let url;
  try {
    url = new URL(str);
  } catch (_) {
    return false;  
  }
  return url.protocol === "http:" || url.protocol === "https:";
}
export function isJson(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}
export function removeAccents (str) {
  str = str.replace(/(à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ)/ig, 'a')
  str = str.replace(/(è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ|ë)/ig, 'e')
  str = str.replace(/(ì|í|ị|ỉ|ĩ)/ig, 'i')
  str = str.replace(/(ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ)/ig, 'o')
  str = str.replace(/(ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ)/ig, 'u')
  str = str.replace(/(ỳ|ý|ỵ|ỷ|ỹ)/ig, 'y')
  str = str.replace(/(đ)/ig, 'd')
  return str
}
export function convertObjectToQueryString (params) {
  return Object.keys(params).map(key => key + '=' + params[key]).join('&')
}
export function stringEquality (str, str2) {
  return cleanSearchKey(str) === cleanSearchKey(str2)
}
export function objEquality (str, str2) {
  return JSON.stringify(str) === JSON.stringify(str2)
}
export function cleanSearchKey (searchQuery) {
  if (!searchQuery) return ''
  let newValue = searchQuery.toLowerCase().trim()
  newValue = newValue.replace(/ +/g, '')
  newValue = removeAccents(newValue)
  return newValue
}
export function networkMenu(id) {
  return [
    {
      label: 'Đơn hàng',
      url: `/network/${id}/invoice`
    },
    {
      label: 'Sản phẩm',
      url: `/network/${id}/network-items`
    },
    {
      label: 'Báo cáo',
      url: `/network/${id}/report`
    },
    {
      label: 'Đại lý',
      url: `/network/${id}/profiles`
    },
    {
      label: 'Kho hàng',
      url: `/store`
    }
  ]
}
export function slugify(str) {
  var slug = str.toLowerCase();

  slug = slug.replace(/(à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ)/ig, 'a')
  slug = slug.replace(/(è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ|ë)/ig, 'e')
  slug = slug.replace(/(ì|í|ị|ỉ|ĩ)/ig, 'i')
  slug = slug.replace(/(ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ)/ig, 'o')
  slug = slug.replace(/(ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ)/ig, 'u')
  slug = slug.replace(/(ỳ|ý|ỵ|ỷ|ỹ)/ig, 'y')
  slug = slug.replace(/(đ)/ig, 'd')
  slug = slug.replace(/[^a-zA-Z ]/g, '')

  slug = slug.replace(/ /gi, "-");
  slug = slug.replace(/----/gi, "-");
  slug = slug.replace(/---/gi, "-");
  slug = slug.replace(/--/gi, "-");
  return trimChar(slug, '-')
}
export function trimChar(string, charToRemove) {
  while (string.charAt(0) === charToRemove) {
    string = string.substring(1);
  }

  while (string.charAt(string.length - 1) === charToRemove) {
    string = string.substring(0, string.length - 1);
  }

  return string;
}
export function parseInt(str) {
  const parsed = Number.parseFloat(str)
  if (Number.isNaN(parsed)) {
    return false
  }
  return parsed
}
export function cloneObj(obj) {
  return JSONParse(JSON.stringify(obj))
}
export function JSONParse(str) {
  try {
    return (JSON.parse(str))
  } catch (error) {
    return null
  }
}
export function randomIntFromInterval(min = 0, max = 100) { // min and max included 
  return Math.floor(Math.random() * (max - min + 1) + min);
}
export const imageDimensions = file =>
  new Promise((resolve, reject) => {
    const img = new Image()

    // the following handler will fire after the successful loading of the image
    img.onload = () => {
      const { naturalWidth: width, naturalHeight: height } = img
      resolve({ width, height })
    }

    // and this handler will fire if there was an error with the image (like if it's not really an image or a corrupted one)
    img.onerror = () => {
      reject('There was some problem with the image.')
    }

    img.src = file
  })
export function isAdminNetwork(networkId) {
  try {
    return store.getState().authentication.user.network_owners.includes(
      Number(networkId)
    );
  } catch (e) {
    return false
  }
}
export function getCurrenProfile() {
  try {
    return store.getState().authentication.user.profile_info
  } catch (e) {
    return {}
  }
}
export function getCurrenUser() {
  try {
    return store.getState().authentication.user
  } catch (e) {
    return null
  }
}
export function getProfileNetwork() {
  try {
    return store.getState().authentication.networks
  } catch (e) {
    return null
  }
}
export function pythonDateToVnDate(strDate) {
  if (strDate)
    return moment(
      strDate,
      process.env.REACT_APP_PYTHON_DATE_FORMAT
    ).format(process.env.REACT_APP_DATE_FORMAT)
  return null
}
export function pythonDateToVnDateTime(strDate) {
  if (strDate)
    return moment(
      strDate,
      process.env.REACT_APP_PYTHON_DATETIME_FORMAT
    ).format(process.env.REACT_APP_DATETIME_FORMAT)
  return null
}
export function vnDateTopythonDate(strDate) {
  if (strDate)
    return moment(
      strDate,
      process.env.REACT_APP_DATE_FORMAT
    ).format(process.env.REACT_APP_PYTHON_DATE_FORMAT)
  return null
}
export function vnDateTimeTopythonDate(strDatetime) {
  if (strDatetime)
    return moment(
      strDatetime,
      process.env.REACT_APP_DATETIME_FORMAT
    ).format(process.env.REACT_APP_PYTHON_DATETIME_FORMAT)
  return null
}
// export function dateRange(startDate, endDate) {
//   var start = startDate.split('/');
//   var end = endDate.split('/');
//   var startYear = parseInt(start[0]);
//   var endYear = parseInt(end[0]);
//   var dates = [];

//   for (var i = startYear; i <= endYear; i++) {
//     var endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
//     var startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
//     for (var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
//       var month = j + 1;
//       var displayMonth = month < 10 ? '0' + month : month;
//       dates.push([i, displayMonth, '01'].join('-'));
//     }
//   }
//   return dates;
// }
export function monthRange(startDate, endDate) {
  var start = startDate.split('/');
  var end = endDate.split('/');
  var startYear = parseInt(start[2]);
  var endYear = parseInt(end[2]);
  var dates = [];

  for (var i = startYear; i <= endYear; i++) {
    var endMonth = i !== endYear ? 11 : parseInt(end[0]) - 1;
    var startMon = i === startYear ? parseInt(start[0]) - 1 : 0;
    for (var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
      var month = j + 1;
      var displayMonth = month < 10 ? '0' + month : month;
      dates.push(
        { displayMonth: displayMonth, month, year: i }
      );
    }
  }
  return dates;
}