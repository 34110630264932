import BaseService from '../base.service'

export class WarrantyService extends BaseService {
  /**
   * The constructor for the ArtistService.
   *
   * @param {Object} parameters The query parameters.
   */
  constructor (parameters = {}) {
    super('warranty/' + process.env.REACT_APP_NETWORK_ID, parameters)
  }
  active (data) {
    return this.submit('post', `/${this.endpoint}/active`, data)
  }
  answer (data, id) {
    if (id) return this.submit('post', `/${this.endpoint}/feedback/answer/${id}`, data)
    return this.submit('post', `/${this.endpoint}/feedback/answer`, data)
  }
  questions () {
    return this.submit('get', `/${this.endpoint}/feedback/question`)
  }
  feedback (id) {
    return this.submit('get', `/${this.endpoint}/feedback/answer/${id}`)
  }
}
